<template>
  <div class="filter-rule">
    <div class="filter-rule__content">
      <SlValidate
        v-slot="{ invalid }"
        :vid="`${rule.ruleId}_column`"
        mode="eager"
        :rules="{
          required: !hasCompletedRule
        }"
        class="w-320"
      >
        <SlSelect
          v-model="columnModel"
          :options="columns"
          :placeholder="$t('Web.Filters.ColumnPlaceholder')"
          track-by="columnKey"
          label="name"
          :is-invalid="invalid"
          :disabled="disabled"
          class="filter-rule__content--column"
          clear-on-select
          return-object
          inline
          searchable
          @input="handleColumnUpdate"
        />
      </SlValidate>
      <SlSelect
        v-model="relationModel"
        :options="relationOptions"
        :disabled="isRelationDisabled"
        inline
        @input="handleRelationUpdate"
      >
        <template #singleLabel>
          <div class="relation-label">
            <icon
              v-if="currentRelation.icon"
              :data="getIcon(currentRelation.icon)"
              class="fill-off size-16"
            />
            {{ currentRelation.label }}
          </div>
        </template>
        <template #selectOption="{ option, selected }">
          <div class="relation-option">
            <icon
              v-if="option.icon"
              :data="getIcon(option.icon)"
              class="fill-off size-16"
            />
            <div class="relation-option__label">{{ option.label }}</div>
            <icon
              v-if="selected"
              data="@icons/check.svg"
              class="fill-off size-16 color-primary-80 check-icon"
            />
          </div>
        </template>
      </SlSelect>
      <SlValidate
        v-slot="{ invalid }"
        :vid="`${rule.ruleId}_value`"
        mode="eager"
        :rules="{
          required: !isValueDisabled && !hasCompletedRule
        }"
      >
        <SlDatePicker
          v-if="checkIsType(columnTypes.DATE)"
          v-model="dateModel"
          :disabled="isValueDisabled"
          :is-invalid="invalid"
          class="w-full"
          @submit="handleDateUpdate"
        />
        <SlSelect
          v-else-if="checkIsType(columnTypes.ENUM)"
          v-model="selectModel"
          :options="valueOptions"
          track-by="id"
          label="name"
          :disabled="isValueDisabled"
          :placeholder="$t('Web.Filters.EnumPlaceholder')"
          :is-invalid="invalid"
          searchable
          @close="handleSelectUpdate"
        />
        <SlCombobox
          v-else-if="checkIsType(columnTypes.STRING)"
          v-model="comboboxModel"
          :options="valueOptions"
          :disabled="isValueDisabled"
          :placeholder="$t('Web.Filters.StringPlaceholder')"
          :is-invalid="invalid"
          :close-on-select="false"
          class="w-full"
          allow-empty
          multiple
          clearable
          clear-on-select
          @remove="handleComboboxRemoveTag"
          @clear="handleComboboxUpdate"
          @close="handleComboboxUpdate"
        />
        <div
          v-else
          class="filter-rule__content--number"
        >
          <SlInput
            v-model="numberModel"
            :placeholder="$t('Web.Filters.NumberPlaceholder')"
            :disabled="isValueDisabled"
            :float="isPercent"
            type="number"
            :is-invalid="invalid"
            @blur="handleNumberUpdate"
            @keydown.enter="handleNumberUpdate"
          />
          <span
            v-if="isPercent"
            class="heading-5 grey-70"
          >
            %
          </span>
        </div>
      </SlValidate>
    </div>
    <RuleActionsDropdown
      v-if="!disabled"
      :rule="rule"
      :actions="rule.actions"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RuleActionsDropdown from '@/components/Filter/RuleActionsDropdown.vue';
import { columnTypes, noValueRelations, relationOptions } from '@/config/filter';
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'FilterRule',
  components: {
    RuleActionsDropdown
  },
  props: {
    rule: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      columnTypes,
      column: null,
      searchableColumns: [columnTypes.ENUM, columnTypes.STRING],
      isChanged: false
    };
  },
  computed: {
    ...mapState({
      columns: state => state.filter.columns,
      columnValues: state => state.filter.column_values
    }),
    ...mapGetters({
      hasCompletedRule: 'filter/hasCompletedRule',
      columnByKeyMap: 'filter/columnByKeyMap'
    }),
    columnModel: {
      get() {
        return this.rule.columnKey;
      },
      set({ columnKey }) {
        this.$set(this.rule, 'columnKey', columnKey);
      }
    },
    relationModel: {
      get() {
        return this.rule.relation;
      },
      set(value) {
        this.$set(this.rule, 'relation', value);
      }
    },
    numberModel: {
      get() {
        return typeof this.ruleValue !== 'object' ? this.ruleValue : '';
      },
      set(value) {
        this.updateModel(typeof value === 'number' ? value : null);
      }
    },
    dateModel: {
      get() {
        return this.ruleValue;
      },
      set(value) {
        this.updateModel(value);
      }
    },
    selectModel: {
      get() {
        return this.ruleValue;
      },
      set(value) {
        this.updateModel(value);
      }
    },
    comboboxModel: {
      get() {
        return toArray(this.ruleValue);
      },
      set(value) {
        this.updateModel(value);
      }
    },
    ruleValue() {
      return this.rule.val;
    },
    valueOptions() {
      return this.columnValues[this.rule.columnKey] || [];
    },
    relationOptions() {
      return relationOptions(this)[this.columnType];
    },
    currentRelation() {
      return this.relationOptions.find(option => option.value === this.rule.relation);
    },
    columnType() {
      return this.column?.type || columnTypes.STRING;
    },
    isRelationDisabled() {
      return !this.rule.columnKey || this.disabled;
    },
    isValueDisabled() {
      return this.isRelationDisabled || noValueRelations.includes(this.rule.relation);
    },
    isPercent() {
      return this.column?.isPercent;
    }
  },
  mounted() {
    this.setColumn(this.rule.columnKey);

    if (this.column) {
      this.loadColumnValues(this.column);
    }
  },
  updated() {
    this.setColumn(this.rule.columnKey);
  },
  methods: {
    ...mapActions({
      changeFilterRule: 'filter/changeFilterRule',
      fetchFilterableColumnValues: 'filter/fetchFilterableColumnValues'
    }),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    getFilteredValue(option) {
      if (this.ruleValue.length === 1) {
        return null;
      }

      return this.ruleValue?.filter(value => value !== option);
    },
    checkIsType(type) {
      return this.column?.type === type;
    },
    setColumn(key) {
      this.column = this.columnByKeyMap[key] || null;
    },
    updateModel(value) {
      this.isChanged = true;

      this.$set(this.rule, 'val', value);
    },
    updateFilterRule(value) {
      return this.changeFilterRule({
        ruleId: this.rule.ruleId,
        value
      });
    },
    async updateFilterRuleValue(val) {
      if (!this.isChanged) {
        return;
      }

      await this.updateFilterRule({ val });

      this.isChanged = false;
    },
    loadColumnValues({ columnKey, type, class: columnClass }) {
      const isSearchable = this.searchableColumns.includes(type);
      const isColumnValuesExist = this.columnValues[columnKey];

      if (!isSearchable || isColumnValuesExist) {
        return;
      }

      this.fetchFilterableColumnValues({
        columnKey,
        class: columnClass
      });
    },
    async handleColumnUpdate(column) {
      const { columnKey } = column;

      await this.updateFilterRule({ columnKey });

      this.column = this.columnByKeyMap[columnKey];

      this.loadColumnValues(column);
    },
    handleRelationUpdate(relation) {
      this.updateFilterRule({ relation });
    },
    handleNumberUpdate() {
      const val = this.ruleValue !== null ? +this.ruleValue : null;

      this.updateFilterRuleValue(val);
    },
    handleDateUpdate() {
      this.updateFilterRuleValue(this.ruleValue);
    },
    handleComboboxUpdate() {
      const val = this.ruleValue?.length ? this.ruleValue : null;

      this.updateFilterRuleValue(val);
    },
    handleComboboxRemoveTag(option) {
      this.isChanged = true;

      this.updateFilterRuleValue(this.getFilteredValue(option));
    },
    handleSelectUpdate() {
      this.updateFilterRuleValue(this.ruleValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/filter/filter-rule.scss';
</style>