<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        size="xs"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(group)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 color-grey-50"
            :data="require(`@icons/${action.icon}.svg`)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'GroupActionsDropdown',
  props: {
    group: {
      type: Object,
      required: true
    },
    actions: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.Filters.Delete'),
          icon: 'trash',
          action: ({ groupId }) => this.deleteFilterGroup(groupId)
        },
        {
          flag: 1,
          label: this.$t('Web.Filters.Duplicate'),
          icon: 'copy',
          action: ({ groupId }) => this.duplicateFilterGroup(groupId)
        },
        {
          flag: 2,
          label: this.$t('Web.Filters.Ungroup'),
          icon: 'convert',
          action: ({ groupId }) => this.ungroupFilterGroup(groupId)
        }
      ]
    };
  },
  computed: {
    allowedActions() {
      return this.actionsConfig.filter(action => this.actions & 1 << action.flag);
    }
  },
  methods: {
    ...mapActions({
      deleteFilterGroup: 'filter/deleteFilterGroup',
      duplicateFilterGroup: 'filter/duplicateFilterGroup',
      ungroupFilterGroup: 'filter/ungroupFilterGroup'
    })
  }
};
</script>