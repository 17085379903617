<template>
  <div class="filter-builder">
    <div class="heading-5-sb">
      {{ $t('Web.Filters.FilterConditions') }}
    </div>
    <div
      v-if="!isInitializing"
      class="filter-builder__content"
    >
      <FilterGroup
        v-if="predicates.rules"
        :group="predicates"
        :disabled="!isEditable"
      />
      <FilterRule
        v-else
        :rule="predicates"
        :disabled="!isEditable"
      />
    </div>
    <div class="filter-builder__footer">
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="!isEditable"
        @click="createFilterRule()"
      >
        <template #prepend>
          <icon
            data="@icons/plus.svg"
            class="size-16 fill-off"
          />
        </template>
        {{ $t('Web.Filters.AddRule') }}
      </SlButton>
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="!isEditable"
        @click="createFilterGroup()"
      >
        <template #prepend>
          <icon
            data="@icons/folder_plus.svg"
            class="size-16 fill-off"
          />
        </template>
        {{ $t('Web.Filters.AddGroup')}}
      </SlButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FilterGroup from '@/components/Filter/FilterGroup.vue';
import FilterRule from '@/components/Filter/FilterRule.vue';

export default {
  name: 'FilterBuilder',
  components: {
    FilterRule,
    FilterGroup
  },
  props: {
    filterId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      predicates: state => state.filter.filter?.predicates || {},
      isEditable: state => state.filter.filter?.editable,
      isInitializing: state => state.filter.is_initializing
    })
  },
  created() {
    this.initializeFilter(this.filterId);
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      initializeFilter: 'filter/initializeFilter',
      createFilterRule: 'filter/createFilterRule',
      createFilterGroup: 'filter/createFilterGroup',
      resetState: 'filter/resetState'
    })
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/filter/filter-builder.scss';
</style>