<template>
  <div
    class="filter-group"
    :class="{ 'filter-group--root': isRoot }"
  >
    <div class="filter-group__content">
      <div class="filter-group__content-main">
        <SlSelect
          v-if="hasNested"
          :value="group.logicOp"
          :options="logicOptions"
          :disabled="disabled"
          class="filter-group__logic w-72"
          @input="handleLogicOpUpdate"
        />
        <div
          v-if="rules.length"
          class="filter-group__predicates"
        >
          <div
            v-for="(rule, ruleIndex) in rules"
            :key="rule.groupId || rule.ruleId"
            class="filter-group__predicate-item"
            :class="{
              'filter-group__predicate-item--rule': !rule.rules,
              'filter-group__predicate-item--nested': hasNested,
              'filter-group__predicate-item--last': checkIsLastPredicate(ruleIndex)
            }"
          >
            <FilterGroup
              v-if="rule.rules"
              :group="rule"
              :disabled="disabled"
            />
            <FilterRule
              v-else
              :rule="rule"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
      <SlButton
        v-if="!isRoot"
        variant="text"
        color="grey"
        @click="createFilterRule(group.groupId)"
      >
        <template #prepend>
          <icon
            data="@icons/plus.svg"
            class="size-16 fill-off"
          />
        </template>
        {{ $t('Web.Filters.AddRule') }}
      </SlButton>
    </div>
    <GroupActionsDropdown
      v-if="!isRoot && !disabled"
      :group="group"
      :actions="group.actions"
      class="filter-group__actions"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FilterRule from '@/components/Filter/FilterRule.vue';
import GroupActionsDropdown from '@/components/Filter/GroupActionsDropdown.vue';
import { logicOperationOptions, ROOT_GROUP_ID } from '@/config/filter';

export default {
  name: 'FilterGroup',
  components: {
    FilterRule,
    GroupActionsDropdown
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      logicOptions: logicOperationOptions(this)
    };
  },
  computed: {
    rules() {
      return this.group.rules || [];
    },
    isRoot() {
      return this.group.groupId === ROOT_GROUP_ID;
    },
    hasNested() {
      return this.rules.length > 1;
    }
  },
  methods: {
    ...mapActions({
      createFilterRule: 'filter/createFilterRule',
      changeFilterGroup: 'filter/changeFilterGroup'
    }),
    checkIsLastPredicate(index) {
      return this.rules.length > 1 && index === this.rules.length - 1;
    },
    handleLogicOpUpdate(logicOp) {
      this.changeFilterGroup({
        groupId: this.group.groupId,
        value: {
          logicOp
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/filter/filter-group.scss';
</style>